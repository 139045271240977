.roboto-serif-unique-class-name {
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 700; /* Replace with actual weight */
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;
}
.roboto-serif-unique {
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 500; /* Replace with actual weight */
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;
}
.roboto {
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 300; /* Replace 400 with the desired font weight */
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;
}
