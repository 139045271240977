.philosopher-regular {
  font-family: "Philosopher", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.ephesis-regular {
  font-family: "Ephesis", cursive;
  font-weight: 500;
  font-style: normal;
}
.philosopher-regular-italic {
  font-family: "Philosopher", sans-serif;
  font-weight: 400;
  font-style: italic;
}
