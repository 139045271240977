.piazzolla-uniquifier {
  font-family: "Piazzolla", serif;
  font-optical-sizing: auto;
  font-weight: 400; /* Replace 400 with the desired weight */
  font-style: normal;
}
.roboto-serif-custom {
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 400; /* Replace with desired font weight */
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;
}

.roboto-serif-header {
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 400; /* Replace with desired font weight */
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;
}