
.swiper {
  padding: 2rem;
}

.swiper .res-slide {
  min-width: 300px;
  max-width: 400px;
  min-height: 350px;
  border: 2px solid #9F7161;
  border-radius: 20px;
}
.swiper-nav {
  text-align: center;
}

.swiper-nav button {
  width: 60px;
  height: 50px;
  border-radius: 50px;
  border: none;
  outline: none;
  text-align: center;
  background-color: #B89C85;
  color: #FFF;
  margin: 0px 0.5rem;
  box-shadow: 2px 2px 2px rgba(#000);
  margin-top: 30px;
  transition: all .5s;
}

.swiper-nav button:hover {
  background-color: #B89C85;
  cursor: pointer;
}

.nunito-sans-uniquifier {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}
.nunito-sans-bold {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: "wdth" 100, "YTLC" 500;
}
.odor-mean-chey-regular {
  font-family: "Odor Mean Chey", serif;
  font-weight: 400;
  font-style: normal;
}
.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

