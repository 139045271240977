.source-code-pro-uniquifier {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
}
.nunito-sans-uniquifier {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}
.abhaya-libre-semibold {
  font-family: "Abhaya Libre", serif;
  font-weight: 600;
  font-style: normal;
}
