
.swiper {
  padding: 2rem;
}

.swiper .res-slide {
  min-width: 300px;
  max-width: 400px;
  min-height: 350px;
  border: 2px solid hsl(210, 100%, 50%);
  border-radius: 20px;
}

.btn-nav-btns button {
  width: 60px;
  height: 50px;
  border-radius: 50px;
  border: none;
  outline: none;
  text-align: center;
  background-color: #D6763B;
  color: #fff;
  margin: 0px 0.5rem;
  box-shadow: 2px 2px 2px rgba(#fff);
  margin-top: 30px;
  transition: all .5s;
}

.btn-nav-btns button:hover {
  background-color: #D4B294;
  cursor: pointer;
}
.btn-nav-btns{
  text-align: center;
}
.XYZ{
  font-family: "Bungee Shade", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.bellota-text-bold {
  font-family: "Bellota Text", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.bellota {
  font-family: "Bellota Text", sans-serif;
  font-weight: 400;
  font-style: normal;
}