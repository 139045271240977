/* src/Components/style.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .open-sans-special {
    font-optical-sizing: auto;
    font-variation-settings: "wdth" 100;
    font-style: normal;
  }
}

/* Additional styles or imports as needed */
