.swiper {
  padding: 2rem;
}

.swiper .res-slide {
  min-width: 300px;
  max-width: 400px;
  min-height: 350px;
  border: 2px solid (#224F34);
  border-radius: 20px;
}

.swiper-nav-btns button {
  width: 60px;
  height: 50px;
  border-radius: 50px;
  border: none;
  outline: none;
  text-align: center;
  background-color: #D6763B;
  color: #000;
  margin: 0px 0.5rem;
  box-shadow: 2px 2px 2px rgba(#000);
  margin-top: 10px;
  transition: all .5s;
}

.swiper-nav-btns button:hover {
 background-color: #224F34;
  cursor: pointer;
}
.engagement-regular {
  font-family: "Engagement", cursive;
  font-weight: 400;
  font-style: normal;
}
.enriqueta-semibold {
  font-family: "Enriqueta", serif;
  font-weight: 600;
  font-style: normal;
}
.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}
