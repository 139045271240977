.swiper {
  padding: 2rem;
}

.swiper .res-slide {
  min-width: 300px;
  max-width: 400px;
  min-height: 350px;
  border: 2px solid #38cb89;
  border-radius: 20px;
}

.swiper-nav-btns {
  display: flex;
  justify-content: center; /* Center align buttons horizontally */
  margin-top: 30px;
}

.swiper-nav-btns button {
  width: 60px;
  height: 50px;
  border-radius: 50px;
  border: none;
  outline: none;
  text-align: center;
  background-color: #151D09; /* Changed from hsl(#000) to #000 */
  color: #FFF;
  margin: 0px 0.5rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2); /* Changed rgba(#000) to rgba(0, 0, 0, 0.2) */
  transition: all 0.5s;
}

.swiper-nav-btns button:hover {
  background-color: #38cb89; /* Changed from backgroundColor:(#38cb89) to #38cb89 */
  cursor: pointer;
}

.jim-nightshade-regular {
  font-family: "Jim Nightshade", cursive;
  font-weight: 400;
  font-style: normal;
}
.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}