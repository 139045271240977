.swiper {
  padding: 1rem;
}

.swiper .res-slide {
  min-width: 300px;
  max-width: 400px;
  min-height: 350px;
  border: 2px solid hsl(210, 100%, 50%);
  border-radius: 20px;
}

.swiper-btns button {
  width: 60px;
  height: 50px;
  border-radius: 50px;
  border: none;
  outline: none;
  text-align: center;
  background-color: #326C77;
  color: #fff;
  margin: 0px 0.5rem;
  box-shadow: 2px 2px 2px rgba(#fff);
  margin-top: 20px;
  transition: all 0.5s;
}
.swiper-btns {
  text-align: end;
}

.swiper-btns button:hover {
  background-color: #13292d;
  cursor: pointer;
}
.nunito-sans-uniquifier {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-variation-settings: "wdth" 100, "YTLC" 500;
}
.odor-mean-chey-regular {
  font-family: "Odor Mean Chey", serif;
  font-weight: 400;
  font-style: normal;
}
.odor-mean {
  font-family: "Odor Mean Chey", serif;
  font-weight: 800;
  font-style: normal;
}

